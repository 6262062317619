<template>
  <div class="sales-catalog-managment">
    <div class="sales-catalog-managment-tools">
        <el-button @click="show_form=true" type="success">חדש</el-button>
        <el-input style="width:30%; margin:0 5px" v-model="search" placeholder="חיפוש" />
        <el-select v-model="selected_filter_option" class="m-2" placeholder="Select" size="large">
            <el-option
            v-for="option in filter_options"
            :key="option"
            :value="option"
            />
        </el-select>
    </div>
    <div class="sales-catalog-managment-content">
        <table>
            <tr>
                <th>נוצר בתאריך</th>
                <th>שם האוסף</th>
                <th>תאריך התחלה</th>
                <th>תאריך סיום</th>
                <th>פעולות</th>
            </tr>
            <h1 style="color:#fff;" v-if="display_by_filter.length==0">לא קיימות רשומות</h1>
            <template v-for="collection in display_by_filter" :key="collection.uid">
                <tr>
                    <td v-if="collection.created_at?.seconds ">{{new Date(collection.created_at.seconds*1000).toLocaleDateString('he')}}</td>
                    <td v-else>{{new Date(collection.created_at).toLocaleDateString('he')}}</td>
                    <td>{{collection.collection_name}}</td>
                    <td>{{new Date(collection.start_date).toLocaleDateString('he')}}</td>
                    <td>{{new Date(collection.end_date).toLocaleDateString('he')}}</td>
                    <td>
                        <i @click="handle_delete(collection)" class="material-icons delete-icon icon">delete</i>
                        <i @click="handle_edit(collection)" class="material-icons edit-icon icon">edit</i>
                    </td>
                </tr>
            </template>  
        </table>
    </div>
    <SaledCatalogForm :edit="selected_edit" @submit_edit="handle_submit_edit" @close="show_form=false; selected_edit = null;" @submit="handle_submit" v-if="show_form" />
    
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue';
import {get_collections_from_db,delete_collection_from_db} from './sales_catalog/scripts/scripts'
import {alert_confirm}  from '../../../Methods/Msgs'
export default {
    components:{
        SaledCatalogForm:defineAsyncComponent(()=>import('../Shivuk_Rosman/sales_catalog/SaledCatalogForm.vue'))
    },
    setup(){
        
        const selected_edit = ref(null)

        const selected_filter_option = ref('הכל')
        const filter_options = ref([
            'הכל',
            'לא בתוקף',
            'בתוקף',
        ])
        const search = ref('')
        const collections = ref([])
        const show_form = ref(false)

        const display_by_filter = ref(computed(()=>{
            switch (selected_filter_option.value) {
                case 'הכל':
                    return filter_by_search.value
                case 'לא בתוקף':
                    return filter_by_search.value.filter(col=>new Date(col.end_date) <  new Date() || new Date(col.start_date) >  new Date())
                case 'בתוקף':
                    return filter_by_search.value.filter(col => new Date(col.start_date) < new Date() && new Date() < new Date(col.end_date))
            }
        }))
        const filter_by_search = ref(computed(() => {
            if(!search.value) return collections.value
            return collections.value.filter(col=>{
                if(col.collection_name.includes(search.value)) return col
                if(new Date(col.start_date).toLocaleDateString('he').includes(search.value)) return col
                if(new Date(col.end_date).toLocaleDateString('he').includes(search.value)) return col
            })
        }))

        const handle_edit = (collection) => {
            selected_edit.value = JSON.parse(JSON.stringify(collection))
            show_form.value = true
        }

        const handle_submit_edit = (data) => {
            const index = collections.value.findIndex(col => col.uid == data.uid)
            if(index!=-1){
                collections.value[index] = data
            }
            show_form.value = false
            selected_edit.value = null
        }

        const handle_submit = (collection) => {
            collections.value.push(collection)
            show_form.value = false
        }

        const handle_delete = (collection) => {
            alert_confirm(`למחוק את ${collection.collection_name}?`)
            .then(async res=>{
                if(res.isConfirmed){
                    await delete_collection_from_db(collection)
                    collections.value = collections.value.filter(col => col.uid != collection.uid)
                }
            })
        }

        const init = async() => {
            collections.value = await get_collections_from_db()
        }
        init()

        return{
            handle_submit,
            handle_delete,
            handle_edit,
            handle_submit_edit,
            show_form,
            collections,
            search,
            filter_by_search,
            filter_options,
            selected_filter_option,
            display_by_filter,
            selected_edit
         }
    }
}
</script>

<style scoped>
    .sales-catalog-managment{
        position: relative;
        width: 100%;
        height: 100%;
    }
    .sales-catalog-managment-tools{
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .sales-catalog-managment-content{
        width: 100%;
        height: calc(100% - 50px);
        overflow: hidden;
        overflow: auto;
    }
    .sales-catalog-managment{
        width: 100%;
        height: 100%;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 2px;       
    }
    table tr:nth-child(even){background-color: #f2f2f2;}
    table tr:nth-child(odd){background-color: #e7e6e6;}
    table tr:hover {background-color: #ddd;}
    table td{
        text-align: center;
        color: var(--main);
        font-size: 18px;
    }
    table th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: var(--purple);
        color: white;
        position: sticky;
        top: 0;
        font-size: 20px;
    }
    @media only screen and (max-width: 600px){
        table th {
            position: static;
        }
    }

    .icon{
        cursor: pointer;
        margin: 0 5px;
        font-size: 25px;
    }
    .edit-icon{
        color: var(--warning);
    }
    .delete-icon{
        color: var(--danger);
    }

    
</style>